import 'core-js/stable';
import 'regenerator-runtime/runtime';
import KipsuChat from './KipsuChat';
import './styles/styles.scss';

window.addEventListener('DOMContentLoaded', () => {
    if (typeof global.kipsuChat === 'undefined') {
        const kipsuChat = new KipsuChat();
        if (kipsuChat.config) {
            console.log(kipsuChat.config);
            kipsuChat.start();
            global.kipsuChat = kipsuChat;
        }
    }
});
