import 'whatwg-fetch';

const KIPSU_SCRIPT_NAME = 'kipsu-chat-script';
const KIPSU_IFRAME_ID = 'kipsu-chat';
const KIPSU_CHAT_BUTTON_ID = 'kipsu-chat-button';
const KIPSU_CLOSE_CHAT_ID = 'kipsu-chat-close-button';
const KIPSU_S3_CONFIG = 'https://cdn.kipsu.com/chats/config/';

export default class KipsuChat {
    constructor(config) {
        this.toggleChat = this.toggleChat.bind(this);
        this.hideChat = this.hideChat.bind(this);
        this.showChat = this.showChat.bind(this);
        this.setCloseButtonPosition = this.setCloseButtonPosition.bind(this);

        this.tagAttributes = [];
        this.getTagAttributes();
        if (config) {
            this.config = config;
        } else {
            console.log('config function running...');
            this.setConfig();
        }
        this.start();
    }

    async getRemoteConfig(widgetConfig) {
        const kipsuS3 = `${KIPSU_S3_CONFIG}${widgetConfig}.json`;
        let response;

        if (!widgetConfig) {
            console.warn('No configuration set for chat widget');
            return false;
        }
        try {
            response = await fetch(kipsuS3);
            this.config = await response.json();
        } catch (e) {
            console.warn('Unable to fetch remote config - please check settings', e);
        }
        return true;
    }

    setConfig(config) {
        const scriptConfig = document.getElementById(KIPSU_SCRIPT_NAME).getAttribute('data-config');
        const widgetConfig = document.getElementById(KIPSU_SCRIPT_NAME).getAttribute('data-widget');
        if (config) {
            this.config = config;
            return true;
        }
        if (scriptConfig) {
            try {
                this.config = JSON.parse(scriptConfig);
            } catch (err) {
                // load remote config
                console.warn('invalid script config');
            }
        } else if (widgetConfig) {
            // get config from s3 instead
            this.config = this.getRemoteConfig(widgetConfig);
        } else {
            this.config = {
                widget: '123/4567',
            };
        }
    }

    start() {
        /**
         * config settings
         * widget: the id and unique hash for the widget as defined in Kipsu: e.g. 122/98d1
         * buttonText: the text for the button that opens the iframe chat
         * stylesheet: URL for the styles to be used for the buttons and iframe - NOT the styles within the iframe
         */
        this.getStyles();

        const status = localStorage.getItem('kipsu-chat-open');
        if (status && status === 'true') {
            console.log('chat already open...');
            this.showChat();
        }

        this.makeButton();
        this.getGuestIdentifier();
        window.addEventListener('resize', () => {
            this.setCloseButtonPosition();
        });
        // setTimeout(this.setCloseButtonPosition, 500, this);
    }

    makeIframe() {
        console.log('making iframe');
        if (!document.getElementById(KIPSU_IFRAME_ID)) {
            const box = document.createElement('iframe');
            box.id = KIPSU_IFRAME_ID;
            box.classList.add('hidden');
            document.getElementsByTagName('body')[0].appendChild(box);
            this.iframe = document.getElementById(KIPSU_IFRAME_ID);
        }
    }

    makeButton() {
        const chatButton = document.createElement('button');
        const buttonText = Object.prototype.hasOwnProperty.call(this.config, 'buttonText') ? this.config.buttonText : 'Live Chat';
        chatButton.innerHTML = `<i class='kip kip-message-bubble'></i>&nbsp; ${buttonText}`;
        chatButton.id = KIPSU_CHAT_BUTTON_ID;
        chatButton.addEventListener('click', this.toggleChat);
        document.getElementsByTagName('body')[0].appendChild(chatButton);
    }

    makeCloseButton() {
        const box = document.createElement('div');
        box.innerHTML = "<i class='kip kip-cancel'></i>";
        box.id = KIPSU_CLOSE_CHAT_ID;
        box.addEventListener('click', this.hideChat);
        document.getElementsByTagName('body')[0].appendChild(box);
        this.closeButton = document.getElementById(KIPSU_CLOSE_CHAT_ID);
    }

    setCloseButtonPosition() {
        const chatWidgetDimensions = this.iframe.getBoundingClientRect();
        const chatCloseButton = this.closeButton;
        if (chatCloseButton) {
            chatCloseButton.style.top = `${chatWidgetDimensions.top}px`;
            // eslint-disable-next-line prefer-template
            chatCloseButton.style.left = (chatWidgetDimensions.right - (chatCloseButton.clientWidth * 1.8)) + 'px';
        }
    }

    showChat() {
        let env = '';
        if (!document.getElementById(KIPSU_IFRAME_ID)) {
            this.makeIframe();
        }
        if (this.iframe && this.iframe.classList.contains('hidden')) {
            this.iframe.classList.remove('hidden');
        }
        if (this.iframe.src === '') {
            if (this.config.hasOwnProperty('env')) {
                env = `${this.config.env}`;
            } else {
                env = 'https://secure.kipsu.com/';
            }
            this.iframe.src = `${env}acct/widget/${this.config.widget}`;
        }
        if (document.getElementById(KIPSU_CLOSE_CHAT_ID)) {
            this.closeButton.classList.remove('hidden');
        } else {
            this.makeCloseButton();
        }
        localStorage.setItem('kipsu-chat-open', true);
        this.setCloseButtonPosition();
    }

    hideChat() {
        this.iframe.classList.add('hidden');
        this.closeButton.classList.add('hidden');
        localStorage.setItem('kipsu-chat-open', false);
    }

    toggleChat() {
        if (!this.iframe || this.iframe.classList.contains('hidden')) {
            this.showChat();
        } else {
            this.hideChat();
        }
    }

    getTagAttributes() {
        let tag = document.getElementById(KIPSU_SCRIPT_NAME);
        if(!tag) {
            const regex = /kipsuChat\.bundle\.js/;
            tag = [...document.getElementsByTagName('script')].find((el) => {
                return regex.exec(el.getAttribute('src'));
            });
        }

        this.tagAttributes.widget = tag.getAttribute('widget');
    }

    getGuestIdentifier() {
        if (this.config.hasOwnProperty('widget')) {
            this.widgetId = this.config.widget.split('/')[0];
            this.guestIdentifier = localStorage.getItem(`kipsu-widget-${this.config.widgetId}-guestIdentifier`);
            if (this.guestIdentifier) {
                console.log(this.guestIdentifier);
            }
        } else {
            console.error('Widget id is not defined');
        }
    }

    getStyles() {
        const stylePath = this.config.hasOwnProperty('stylesheet') ? this.config.stylesheet : '';
        if (!stylePath) {
            return false;
        }
        const style = document.createElement('link');
        style.rel = 'stylesheet';
        style.href = stylePath;
        document.head.appendChild(style);
    }
}
